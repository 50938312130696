import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink} from 'react-router-dom';

import { getUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';
import Slider from "react-slick";

//MUI
import { Link,Container,} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

//ICON
import { FaGift, FaTruck } from "react-icons/fa6";
import { IoSettingsSharp } from "react-icons/io5";
import { FiChevronRight } from "react-icons/fi";

//COMPONENTS & LAYOUTS
import TitleBar from '@layouts/TitleBar';
import AnnouncementCard from '@components/AnnouncementCard';
import PromotionsCard from '@components/PromotionsCard';

export default function Notification() {
    const [unreadMallUpdate, setunreadMallUpdate] = useState();
    const [unreadPromote, setunreadPromote] = useState();
    const [unreadPrizeNotification, setunreadPrizeNotification] = useState();
    const [unreadAnnoucement, setunreadAnnoucement] = useState();
    const [announcements, setAnnouncements] = useState([]);
    const [unreadCount, setUnreadAnnouncementCount] = useState(0);
    const [promotions, setPromotions] = useState([]);

    const { t} = useTranslation();
    const styles = useStyles();
    
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    // const theme = useTheme();

    // const [notifications, setNotifications] = useState([]);
    // const [page, setPage] = useState(1);
    // const [totalPage, setTotalPage] = useState(1);

    const settingsAnnouncement = {
        infinite: false,
        speed: 500,
        slidesToShow: 2.25,
        slidesToScroll: 1,
        arrows: false,
    }

    const settingsPromotion = {
        infinite: false,
        speed: 500,
        slidesToShow: 1.25,
        slidesToScroll: 1,
        arrows: false,
    }

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;

        getUrl(`notifications`).then(response => {
            if (response.status) {
                if (isMountedRef.current) {
                    const promotions = response.data.data.filter(notification => notification.type === 'promotions');

                    setunreadMallUpdate(response.new_count_mall_update);
                    setunreadPromote(response.new_count_promote);
                    setunreadPrizeNotification(response.new_count_prize_notification);
                    setunreadAnnoucement(response.new_announcement);
                    setPromotions(promotions);
                    setLoading(false);
                }
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [ addAlert]);

    const getAnnouncements = () => {
        getUrl('member_announcements').then(res => {
            if (isMountedRef.current) {
                setAnnouncements(res.data);
                setUnreadAnnouncementCount(res.new_count);
                setLoading(false);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
            setLoading(false);
        });
    }

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;

        getAnnouncements();

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    return (
        <div>
        <TitleBar btnRegister backgroundColor="bg-theme" />
            <div className='bg-theme p-b-80 header-radius txt-center'>
                <div className='subtitle-box'>
                    <p className='fs-header clwhite p-t-30'><b>{t('profile.notification')}</b></p>
                    <p className='cl-label fs-subheader lh-11'>
                        {t('notification.notificationSubtitle')}</p>
                </div>
            </div>
            <Container>
                <div className='gradient-blue bor5 p-tb-10 p-lr-15 w-full flex-sb-m menu-shadow' style={{marginTop:-40}}>
                    <div style={{ width: '33%' }}>
                        <Link underline='none' to="/notification?mt=mall_update" component={RouterLink}  className='pos-relative'>
                            <div className='flex-c-m flex-col clwhite translateX'>
                                <IoSettingsSharp className='fs-icon' />
                                <p className='p-t-5 txt-upper fs-content'>{t('notification.update')}</p>
                            </div>
                            {unreadMallUpdate > 99 ?
                                <div className={`${styles.unreadiconColor} bor50 flex-c-m pos-absolute`} style={{ right: 10, top: 0, width: 25, height: 25, }} >
                                    <p className='fs-remark'><b>99</b></p>
                                </div>
                                :
                                (unreadMallUpdate > 0 ?
                                    <div className={`${styles.unreadiconColor} bor50 flex-c-m pos-absolute`} style={{ right: 10, top: 0, width: 25, height: 25, }} >
                                        <p className='fs-remark'><b>{unreadMallUpdate}</b></p>
                                    </div>
                                    :
                                    null
                                )}
                        </Link>
                    </div>
                    
                    
                    <div style={{ width: '33%' }}>
                        <Link underline='none' to="/notification?mt=prizes_notification" component={RouterLink}  className='pos-relative'>
                            <div className='flex-c-m flex-col clwhite translateX'>
                                <FaGift className='fs-icon' />
                                <p className='p-t-5 txt-upper fs-content'>{t('notification.prize')}</p>
                            </div>
                            {unreadPrizeNotification > 99 ?
                                <div className={`${styles.unreadiconColor} bor50 flex-c-m pos-absolute`} style={{ right: 10, top: 0, width: 25, height: 25, }} >
                                    <p className='fs-12'><b>99</b></p>
                                </div>
                                :
                                (unreadPrizeNotification > 0 ?
                                    <div className={`${styles.unreadiconColor} bor50 flex-c-m pos-absolute`} style={{ right: 10, top: 0, width: 25, height: 25, }} >
                                        <p className='fs-12'><b>{unreadPrizeNotification}</b></p>
                                    </div>
                                    :
                                    null
                                )}
                        </Link>
                    </div>

                    <div style={{ width: '33%' }}>
                        <Link underline='none' to="/order" component={RouterLink}  className='pos-relative'>
                            <div className='flex-c-m flex-col clwhite translateX'>
                                <FaTruck className='fs-icon' />
                                <p className='p-t-5 txt-upper fs-content'>{t('notification.order')}</p>
                            </div>
                        </Link>
                    </div>
                </div>
                
                <div className='p-t-25'>
                    {/* Announcement */}
                    {_.size(announcements) > 0 &&
                        <div className='p-b-30'>
                            <div className='w-full flex-sb-m p-b-15'>
                                <p className='fs-title cl-theme'>{t('title.announcements')}</p>
                                <Link underline='none' component={RouterLink} to="/announcement" className='flex-m'>
                                    <p className='fs-content cl-theme pointer'>{t('home.more')}</p>
                                    <FiChevronRight className='cl-theme fs-icon-small' />
                                </Link>
                            </div>
                        <Slider {...settingsAnnouncement}>
                            {announcements.slice(0, 6).map((announcement) => (
                                <AnnouncementCard
                                    key={announcement.id}
                                    announcement={announcement}
                                />
                            ))}
                        </Slider>
                    </div>
                    }

                    {/* Promotions */}
                    {_.size(promotions) > 0 &&
                        <div>
                            <div className='w-full flex-sb-m p-b-15'>
                                <p className='fs-title cl-theme'>{t('title.promotions')}</p>
                                <Link underline='none' component={RouterLink} to="/notification?mt=promotions" className='flex-m'>
                                    <p className='fs-content cl-theme pointer'>{t('home.more')}</p>
                                    <FiChevronRight className='cl-theme fs-icon-small' />
                                </Link>
                                
                            </div>
                            <Slider {...settingsPromotion}>
                                {promotions.slice(0, 3).map(promotion => (
                                    <PromotionsCard
                                        key={promotion.id}
                                        promotion={promotion}
                                    />
                                ))}
                            </Slider>
                        </div>
                    }
                </div>
                
                {_.size(promotions) === 0 && _.size(announcements) === 0 ?
                    <div>
                        <div style={{ width: 150, maxWidth: '100%', margin: '0 auto' }}>
                            <img src='/images/empty/promo.png' alt='emptyStatus' />
                        </div>
                        <p className='fs-15 cl-label txt-center'>{t('emptyStatus.annoncePromo')}</p>
                    </div>
                    :
                    null
                }
                
            </Container>
            
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding:'0 0 80px',
        minHeight: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    large: {
        width: 20,
        height: 20,
    },
    
    unreadiconColor: {
        padding: 5,
        width: 25, 
        height: 20,
        backgroundColor:'#FFB545',
        color:"#16114E"
    },
}));