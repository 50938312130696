import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
// import { Link as RouterLink } from 'react-router-dom';
import useNotificationLoading from '@helper/useNotificationLoading';
import { putUrl } from "@helper/ApiAction";
import { storeProfile } from "@actions";

//MUI
import { Drawer, Radio, RadioGroup, FormControlLabel, Container } from '@material-ui/core';

//ICONS
import { FiChevronRight } from 'react-icons/fi';

//COMPONENTS & LAYOUTS
import TitleBar from '@layouts/TitleBar';

export default function Recruit() {

    // const styles = useStyles();
    const { t } = useTranslation();
    // const theme = useTheme();
    const shareLink = useRef(null);
    const { id, max_rank } = useSelector(state => state.user);
    const { additional_info } = useSelector((state) => state.user);
    const { addAlert, setLoading } = useNotificationLoading();
    const dispatch = useDispatch();
    // const [open, setOpen] = React.useState(false);
    // const handleOpen = () => {
    //     setOpen(true);
    // };
    // const handleClose = () => {
    //     setOpen(false);
    // };

    //Copy Button
    const copyShareLink = () => {
        if (max_rank.code > 0 || true) {
            var textField = document.createElement('textarea');
            textField.innerText = `${window.location.origin}/register?r=${btoa(id)}`;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();

            addAlert(t('profile.copySuccess'), "success");
        }
    };

    //Change AutoPlacement
    const [additionalInfoFormState, setAdditionalInfoFormState] = useState({
        ...additional_info,
    });

    const handleAutoPlacementChange = (event) => {
        setLoading(true);

        if (
            event.target.name == "auto_placement_account" &&
            event.target.value == "0"
        ) {
            setAdditionalInfoFormState({
                ...additionalInfoFormState,
                [event.target.name]: event.target.value,
                auto_placement_position: "0",
            });
        } else {
            setAdditionalInfoFormState({
                ...additionalInfoFormState,
                [event.target.name]: event.target.value,
            });
        }
        putUrl("user/additional_info", {
            [event.target.name]: event.target.value,
        }).then((result) => {
            setLoading(false);
            addAlert(result.message, result.status ? "success" : "error");
            if (result.status) {
                dispatch(storeProfile(result.data));
            }
        });
    };

    //Selected text
    const autoplacementText = (value) => {
        switch (value) {
            case '0': return t('profile.auto');
            case '1': return t('profile.left');
            case '2': return t('profile.right');
            default: return null;
        }
    }

    //Drawer
    const [state, setState] = React.useState({
        communitySetting: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    return (
        <div>
            <TitleBar backgroundColor="bg-theme" displayUsername />
            <div className='bg-theme p-b-70 header-radius'>
                <div className='subtitle-box'>
                    <p className='txt-center fs-header clwhite p-t-30'>{t('eventPromo.inviteFriends')}</p>
                    <p className="cl-label fs-subheader lh-11">{t('eventPromo.inviteSubtitle')}</p>
                </div>
            </div>
            <Container style={{ marginTop: -30 }}>
                <div className='p-lr-10'>
                    <div className='gradient-blue bor15 flex-sb-m w-full p-tb-8 p-lr-15 fs-content clwhite menu-shadow shadow-txt' style={{ minHeight: 60 }}>
                        <p>{t('profile.placementSetting')}</p>
                        <div className='flex-m translateX pointer' onClick={toggleDrawer('communitySetting', true)}>
                            <p className='p-r-10'>{autoplacementText(additionalInfoFormState.auto_placement_position)}</p>
                            <FiChevronRight className='fs-icon-small' />
                        </div>
                    </div>
                </div>

                <div className='p-tb-40 p-lr-20'>
                    <img src="/images/general/invite/graphic.png" alt="graphic" />
                </div>

                <div className='p-lr-10'>
                    <p className='txt-center fs-content cl-label'>{t('eventPromo.inviteFriends')}</p>
                    <div>
                        <p className='txt-center p-b-8 fs-title cl-theme-light' style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', }} ref={shareLink} >
                            <b>{(max_rank.code > 0 || true) ? `${window.location.origin}/register?r=${btoa(id)}` : t('eventPromo.activeUserOnly')}</b>
                        </p>
                    </div>
                    <div className='btn-theme w-full txt-center  translateY' onClick={copyShareLink} >
                        <p className='fs-button'><b>{t('eventPromo.inviteNow')}</b></p>
                    </div>
                </div>
            </Container>

            <Drawer anchor="bottom" open={state.communitySetting} onClose={toggleDrawer('communitySetting', false)}>
                <div
                    role="presentation"
                    onClick={toggleDrawer('communitySetting', false)}
                    onKeyDown={toggleDrawer('communitySetting', false)}
                    className='p-lr-30 p-tb-30'
                >
                    <p className='p-b-15 fs-title cl-label'>{t('profile.autoPlacementPosition')} </p>
                    <div>
                        <RadioGroup
                            aria-label="auto_placement_position"
                            column="true"
                            name="auto_placement_position"
                            value={
                                additionalInfoFormState.auto_placement_position
                            }
                            onChange={
                                handleAutoPlacementChange
                            }
                        >
                            <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label={t("profile.auto")}
                            />
                            <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label={t("profile.left")}
                            /* disabled={
                                additionalInfoFormState.auto_placement_account ==
                                "0"
                            } */
                            />
                            <FormControlLabel
                                value="2"
                                control={<Radio />}
                                label={t("profile.right")}
                            /* disabled={
                                additionalInfoFormState.auto_placement_account ==
                                "0"
                            } */
                            />
                        </RadioGroup>
                    </div>
                </div>
            </Drawer>

            {/* <Modal
                className="flex-c-m"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={styles.paper}>
                        <div style={{ textAlign: "center" }}>
                            <h2 style={{ marginBottom: 0 }}>{t('eventPromo.tncTitle')}</h2>
                            <p style={{ marginTop: 5 }}>
                                <b>{t('eventPromo.tncDes')}</b>
                            </p>
                        </div>
                        <p>{t('eventPromo.tncDes1')}</p>
                        <p>{t('eventPromo.tncDes2')}</p>
                        <p>{t('eventPromo.tncDes3')}</p>
                        <p>{t('eventPromo.tncDes4')}</p>
                        <p><i><i style={{ paddingTop: 5 }}>{t('eventPromo.tncDes5')}</i></i></p>
                    </div>
                </Fade>
            </Modal> */}
        </div>

    )
}
