import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, Card, CardContent, Link,Dialog, DialogActions, DialogContent, DialogTitle,Button,Badge,Divider, Container } from '@material-ui/core';
import { makeStyles, useTheme, createMuiTheme, MuiThemeProvider  } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import WebpImg from '@layouts/WebpImg';

import TitleBar from '@layouts/TitleBar';
import { getUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';


export default function Announcement(props) {
    const { id } = useParams();
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [announcements, setAnnouncements] = useState([]);
    const [visibleAnnouncements, setVisibleAnnouncements] = useState([]);
    const [unreadCount, setUnreadAnnouncementCount] = useState(0);

    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    let history = useHistory();

    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    
    const getAnnouncements = () => {
        getUrl('member_announcements').then(res => {
            if (isMountedRef.current) {
                setAnnouncements(res.data);
                setVisibleAnnouncements(res.data.slice(0, 10));
                setTotalPage(Math.ceil(res.data.length / 10));
                setUnreadAnnouncementCount(res.new_count);
                setLoading(false);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
            setLoading(false);
        });
    }

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;

        getAnnouncements();

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    const handleAnnouncementClick = (announcementId) => {
        history.push(`/announcement-detail/${announcementId}`);
    };

    const loadTransaction = () => {
        const nextPage = page + 1;
        const nextVisibleAnnouncements = announcements.slice(0, nextPage * 10);
        setPage(nextPage);
        setVisibleAnnouncements(nextVisibleAnnouncements);
    }

    return (
        <div>
            <TitleBar back btnRegister backgroundColor="bg-theme" />
            <div className={`bg-theme  header-radius txt-center ${_.size(visibleAnnouncements) > 0 ? 'p-b-80' : 'p-b-30'}`}>
                <div className='subtitle-box'>
                    <p className='fs-header clwhite p-t-30'><b>{t('title.announcements')}</b></p>
                    <p className='cl-label fs-subheader lh-11'>
                    {t('notification.announcementSubtitle')}</p>
                </div>
            </div>
            <div style={{marginTop:-50}}>
                <Container>
                    {
                        _.size(visibleAnnouncements) > 0 ?
                           <div>
                                {_.map(visibleAnnouncements, (announcementsItem, annoucementIndex) => {
                                    if (id == announcementsItem.id) {
                                        return (
                                            <div className='glass2 shadow-glass2 bor15 p-all-15' key={annoucementIndex} onClick={() => handleAnnouncementClick(announcementsItem.id)}>
                                                <div className='pointer'>
                                                    <p className='fs-title cl-theme'>{announcementsItem[`title_${i18n.language}`]}</p>
                                                    <p className='fs-content'>{new Date(announcementsItem.updated_at).toLocaleDateString()}</p>
                                                    <p className='fs-content txt_truncate2'><div dangerouslySetInnerHTML={{ __html: announcementsItem['content'][`${i18n.language}`] }} /></p>
                                                </div>
                                            </div>
                                        )
                                    } else if (id == null) {
                                        return (
                                            <div  className='glass2 shadow-glass2 bor15 p-all-15 m-b-20' key={annoucementIndex} onClick={() => handleAnnouncementClick(announcementsItem.id)}>
                                                <div className='pointer'>
                                                    <div className='flex-sb-m w-full cl-theme'>
                                                        <p className='fs-title p-r-15 txt_truncate'>{announcementsItem[`title_${i18n.language}`]}</p>
                                                        <p className='fs-content'>{new Date(announcementsItem.updated_at).toLocaleDateString()}</p>
                                                    </div>
                                                    <p className='fs-content txt_truncate2'><div dangerouslySetInnerHTML={{ __html: announcementsItem['content'][`${i18n.language}`] }} /></p>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                           
                            :
                            <div className='p-t-80'>
                                <div className='empty-img'>
                                    <img src="/images/empty/announce.png" className='w-full' alt="empty" />
                                </div>
                                <p className='fs-empty'>{t('announcement.noAnnouncement')}</p>
                            </div>
                    }

                </Container>
                {
                    _.size(visibleAnnouncements) > 0 &&
                    <div className='flex-c-m w-full p-t-30'>
                        {
                            page < totalPage
                                ? <Button onClick={loadTransaction} fullWidth>{t('transaction.loadMore')}</Button>
                                : <p className='txt-center fs-content'>{t('transaction.endOfList')}</p>

                        }
                    </div>
                }
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    txtTruncate: {
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        minHeight: '2.86em',
        maxHeight: '4.86rem',
        color:'#18267C'
    },
    root2: {
        // padding: '10px 0px 30px 0px',
        background: theme.palette.white.mobileBkg,
        // padding:'0 0 80px',
        minHeight: '100%'
    },
}));

const DialogStyle = createMuiTheme({
    overrides: {
        MuiDialog: {
            paperWidthSm: {
                maxWidth:350
            }
        }
    }
});