import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ImageGallery from 'react-image-gallery';
import _ from 'lodash';

import { Typography, Box, Grid, Tab, Tabs, Button, Divider, ListItemText, Link, IconButton, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Favorite, FavoriteBorder, ShareRounded } from '@material-ui/icons';
import { FaStar } from "react-icons/fa";
import { RiHeartAddFill } from "react-icons/ri";

import useNotificationLoading from '@helper/useNotificationLoading';
import { getUrl, postUrl, deleteUrl } from '@helper/ApiAction';
import { currencyFormat } from '@helper/Tools';
import PropTypes from 'prop-types';

import TitleBar from '@layouts/TitleBar';
import ProductOption from '@pages/Products/ProductOption';
import WebpImg from '@layouts/WebpImg';
import { stringToLang } from '@helper/Tools';
// import { Rating } from '@material-ui/lab';
// import StarBorderIcon from '@material-ui/icons/StarBorder';
import { IoStar } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";

import "@css/image-gallery.css";
import '@css/styles.css';

const INITIAL_STATE = {
    tabIndex: 0,
    variant: 0,
    warehouseId: 0,
    multiPricingId: 0,
    wid: 0,
    open: false,
    error: false,
    quantity: 1,
    message: "",
    product_options: true,
    // shippingRegionDialog: false,
    user_wishlist: false
};

export default function Product() {
    let { id } = useParams();

    const classes = useStyles();
    const theme = useTheme();
    let history = useHistory();
    const isMountedRef = useRef(null);
    const galleryRef = useRef(null);
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { accessToken, currency, currencyRate } = useSelector(state => state.general);
    const { source, id:user_id, rank:user_rank, max_rank } = useSelector(state => state.user);

    const [product, setProduct] = useState({});
    const [category, setCategory] = useState({});
    const [state, setState] = useState(INITIAL_STATE);
    const [warehouse, setWarehouse] = useState([]);
    const [wallets, setWallets] = useState([]);
    const [productReviewSummary, setProductReviewSummary] = useState({
        all_count: 0,
        average_rating: 0,
        comment_count: 0,
        total_sold: 0,
        star_count: {
            1: 0, 2: 0, 3: 0, 4: 0, 5: 0,
        }
    });
    const [shareLink, setShareLink] = useState(window.location.href.split('?')[0]);
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [page, setPage] = useState(0);
    const [review, setReview] = useState([]);

    const lastIndex = _.findLastIndex(category);

    // ---------- APIs -------------
    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);

        if (isMountedRef.current && Number(id)) {
            // --------- Points API ----------
            let pointList = [];
            getUrl(`point_list`).then(response => {
                if (response.status) {
                    pointList = response.data;
                }
            }).catch((error) => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });

            // --------- Product API ----------
            getUrl(`products/${id}`).then(productDetail => {
                if (isMountedRef.current) {
                    if (productDetail.status) {
                        const { data, merchant_company_info, wallets:walletsName } = productDetail;
                        setProduct(data);
                        setWallets(walletsName);

                        // filter product's points
                        let tempPoint = [];
                        let visible = false;
                        let filteredArray = [];
                        setState(state => ({ ...state, user_wishlist: data.user_wishlist }));
                        if (_.size(data.points) > 0) {
                            tempPoint = [];
                            _.map(data.points, (point, key) => {
                                visible = false;
                                if (_.size(pointList) > 0) {
                                    _.map(pointList, visiblePoint => {
                                        if (visiblePoint.id === point.point_id) {
                                            visible = true;
                                        }
                                    })
                                    if (visible) {
                                        tempPoint[key] = point;
                                    }
                                }
                            })
                            filteredArray = tempPoint.filter(item => item !== null);
                            data.points = filteredArray;
                        }

                        if (_.size(data.options) <= 0) {
                            setState(state => ({ ...state, product_options: false }));
                        } else {
                            // selectVariant(data.options[0]);
                            setWarehouse(warehouse => (data.options[0].pricing));

                            if (_.size(data.options[0].pricing) === 1) {
                                setState({ ...state, variant: data.options[0].id, warehoustId: data.options[0].pricing[0].id });
                                // if (parseFloat(data.options[0].pricing[0][priceModDisplay]) > 0) {
                                //     setSellPrice((parseFloat(data.options[0].pricing[0][priceModDisplay])).toFixed(2));
                                // }
                                // if (parseFloat(data.options[0].pricing[0].retail_price) > 0) {
                                //     setRetailPrice(data.options[0].pricing[0].retail_price);
                                // }
                            }
                            if (_.size(data.options[0].pricing) > 0) {
                                _.map(data.options[0].pricing, warehouseOption => {
                                    if (warehouseOption.warehouse_id === state.wid) {
                                        setState({ ...state,variant: data.options[0].id, warehoustId: warehouseOption.id });
                                        // if (parseFloat(warehouseOption[priceModDisplay]) > 0) {
                                        //     setSellPrice((parseFloat(warehouseOption[priceModDisplay])).toFixed(2));
                                        // }
                                        // if (parseFloat(warehouseOption.retail_price) > 0) {
                                        //     setRetailPrice(warehouseOption.retail_price);
                                        // }
                                    }
                                })
                            }
                            // setProductShippingVariant({
                            //     weight: parseFloat(options) ? options[0]['volume_weight'] : 0,
                            //     length: parseFloat(options) ? options[0]['package_length'] : 0,
                            //     width: parseFloat(options) ? options[0]['package_width'] : 0,
                            //     height: parseFloat(options) ? options[0]['package_height'] : 0
                            // });
    
                            // filter options's points
                            _.map(data.options, (option,opKey) => {
                                if(_.size(option.points) > 0){
                                    tempPoint = [];
                                    _.map(option.points, (point, key) => {
                                        visible = false;
                                        if(_.size(pointList) > 0){
                                            _.map(pointList, visiblePoint => {
                                                if(visiblePoint.id === point.point_id){
                                                    visible = true;
                                                }
                                            })
                                            if(visible){
                                                tempPoint[key] = point;
                                            }
                                        }
                                    })
                                    filteredArray = tempPoint.filter(item => item !== null);
                                    data.options[opKey].points = filteredArray;
                                }
                            })
                        }

                        if (data?.category_id) {
                            getUrl(`categories/${data?.category_id}/breadcrumb`).then(categoryBreadcrumb => {
                                setCategory(categoryBreadcrumb.data);
                            })
                        }

                        setState(state => ({ ...state, user_wishlist: data.user_wishlist }));
                    } else {
                        addAlert(JSON.stringify(t('general.invalidProduct')));
                    }
                }
            }).catch((error) => {
                addAlert(JSON.stringify(t('general.invalidProduct')));
            }).finally(() => {
                setLoading(false);
            });

            // --------- Review API ----------
            getUrl(`reviews/${id}/summary`).then(reviewSummary => {
                if(reviewSummary.status) {
                    setProductReviewSummary(reviewSummary.data);
                    setReview(reviewSummary.reveiw)
                }
            })
            .catch((error) => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
        }else{
            setLoading(false);
            history.replace({ pathname: "/" });
        }

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, addAlert]);

    useEffect(() => {
        if(user_id){
            setShareLink(`${window.location.href.split('?')[0]}?r=${btoa(user_id)}`);
        }
        // eslint-disable-next-line
    }, [user_id]);

    useEffect(() => {
        if (isFullscreen) {
            document.addEventListener('fullscreenchange', (e) => {
                if (!document.fullscreenElement) {
                    setIsFullscreen(!isFullscreen);
                }
            });
        }
    }, [isFullscreen])

    // ---------- Functions -------------
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const toggleDrawer = (newOpen) => {
        setOpen(newOpen);
        setPage(0);
        setState({
            ...state,
            tabIndex: 0,
            variant: 0,
            warehouseId: 0,
            multiPricingId: 0,
            wid: 0,
            open: false,
            error: false,
            quantity: 1,
            message: "",
        });
    };

    const likeProduct = () => {
        if (accessToken) {
            postUrl(`wishlist/product`, {product_id: id}).then(response => {
                if (response.status === 1) {
                    setState({ ...state, user_wishlist: true, open: true, error: false, message: t('item.productLiked') });
                    addAlert(t('item.productLiked'), 'success');
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }

    const dislikeProduct = () => {
        if (accessToken) {
            deleteUrl(`wishlist/product/${id}`).then(response => {
                if (response) {
                    if (isMountedRef.current) {
                        setState({ ...state, user_wishlist: false, open: true, error: false, message: t('item.productDislike') });
                        addAlert(t('item.productDislike'), 'success');
                    }
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    };

    const generateImageSlide = ({ images_array, video, embed_video }) => {
        let imgList = [];

        if(_.size(video) > 0 || _.size(embed_video) > 0) {
            if(_.size(embed_video) > 0) video = embed_video;
            const defaultImage = _.size(images_array) > 0 ? images_array[0] : "/images/empty/no-image.png";
            const videoProps = {
                original: defaultImage, 
                thumbnail: defaultImage,
                embedUrl: video,
            }
            imgList.push({ 
                original: defaultImage, 
                thumbnail: defaultImage,
                embedUrl: video,
                renderItem: () => renderVideo(videoProps),
            });
        }

        // imgList.push({ 
        //     original: 'https://images.unsplash.com/photo-1457732815361-daa98277e9c8?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTh8fGdpcmx8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60', 
        //     thumbnail: 'https://images.unsplash.com/photo-1457732815361-daa98277e9c8?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTh8fGdpcmx8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
        //     embedUrl: 'https://www.youtube.com/embed/_26h8SErA0c?autoplay=1&showinfo=0',
        //     description: 'Render custom slides within the gallery',
        //     renderItem: renderVideo,
        // });
        // console.log()
        
        _.map(images_array, (url) => {
            imgList.push({ original: url, thumbnail: url });
        });
        // console.log("imgList", imgList)
        return imgList;
    }

    const handleImageClick = (event) => {
        if (galleryRef.current) {
            if (isFullscreen) {
                galleryRef.current.exitFullScreen();
            } else {
                galleryRef.current.fullScreen();
            }
            setIsFullscreen(!isFullscreen);
        }
    };

    // ---------- Components ------------
    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }

    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const renderVideo = (item) => {
        return (
            <div className='video-wrapper'>
                <div className="iframe-container" style={{ paddingTop: item.embedUrl.toLowerCase().endsWith('.mp4') ? 0 : '56.25%' }}>
                    {
                        item.embedUrl.toLowerCase().endsWith('.mp4')
                            ? <video className='w-full' controls>
                                <source src={item.embedUrl} type="video/mp4" />
                            </video>
                            : <iframe
                                className="responsive-iframe"
                                src={item.embedUrl}
                                frameBorder='0'
                                allowFullScreen
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            >
                            </iframe>
                    }
                </div>
            </div>
        );
    }

    const reviewDisplay = () => {

        function maskUsername(username) {
            if (username.length <= 2) {
                return username;
            }
            const firstChar = username[0];
            const lastChar = username[username.length - 1];
            const maskedPart = '*'.repeat(username.length - 2);
            return `${firstChar}${maskedPart}${lastChar}`;
        }

        const ReviewsList = ({ reviews }) => {
            const [openReviewKey, setOpenReviewKey] = useState(null);
        
            const handleToggle = (reviewKey) => {
                setOpenReviewKey(openReviewKey === reviewKey ? null : reviewKey);
            };
        
            return (
                <div>
                    {_.size(reviews) > 0 ?
                        _.map(reviews, (reviewData, reviewKey) => {
                            const isOpen = openReviewKey === reviewKey;
                            const isLastItem = reviewKey === reviews.length - 1;

                            return (
                                <div key={reviewKey}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                        <img
                                            src={'/images/empty/no-image.png'}
                                            style={{
                                                width: '10%',
                                                height: 'auto',
                                                backgroundColor: 'rgb(24, 38, 124)',
                                                borderRadius: '50%',
                                            }}
                                            alt='product'
                                        />
                                        <div style={{ width: '85%' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Typography style={{ margin: 0, fontWeight: 700 }}>
                                                    {reviewData?.anonymous ? `${reviewData?.customer_name[0]}*****` : reviewData?.customer_name}
                                                </Typography>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        margin: '5px 0',
                                                        borderRadius: 15,
                                                        backgroundColor: 'rgba(237, 237, 237, 1)',
                                                        padding: '10px 15px',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <IoStar style={{ color: 'rgba(255, 191, 66, 1)', fontSize: 20 }} />
                                                    <Typography style={{ color: 'rgba(159, 159, 159, 1)', lineHeight: 'normal', margin: "0px 5px" }}>
                                                        {reviewData?.rating || 0}
                                                    </Typography>
                                                </div>
                                            </div>
                                            <p style={{ marginBottom: 10 }}>
                                                {reviewData?.comment}
                                            </p>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'rgba(159, 159, 159, 1)' }}>
                                                <p>{reviewData?.created_date_display}</p>
                                                {reviewData?.response && (
                                                    <div
                                                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}
                                                        onClick={() => handleToggle(reviewKey)}
                                                    >
                                                        <p>{t('general.sellerResponse')}</p>
                                                        <IoIosArrowDown style={{ margin: '0px 5px', transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {isOpen && (
                                        <div 
                                            style={{ 
                                                margin: '20px 0 20px 50px', 
                                                backgroundColor: 'rgba(234, 234, 234, 1)',  
                                                borderRadius: 15, 
                                                padding: '10px 20px',
                                                color: 'rgba(78, 78, 78, 1)'
                                            }}
                                        >
                                            <p style={{ margin: '10px 0'}}>{reviewData?.response}</p>
                                            <p>{reviewData?.updated_date_display}</p>
                                        </div>
                                    )}
                                    {
                                        isLastItem 
                                        ? (
                                            <Box display="flex" alignItems="center" justifyContent="center" style={{ margin: '20px 0', position: 'relative' }}>
                                                <Divider style={{ flex: 1, borderTop: '2px dashed #D2D2D2' , backgroundColor: 'transparent'}} />
                                                <Box
                                                    style={{
                                                        position: 'absolute',
                                                        background: '#F4F4F4',
                                                        padding: '0 10px',
                                                        right: '45%',
                                                    }}
                                                >
                                                    {t('general.end')}
                                                </Box>
                                            </Box>
                                        )
                                        : (
                                            <Divider style={{ borderTop: '2px dashed #D2D2D2', margin: '20px 0' , backgroundColor: 'transparent'}} />
                                        )
                                    }

                                </div>
                            );
                        })
                        : <p>{t('general.noRemark')}</p>
                    }
                </div>
            );
        };
        return (
            <div style={{margin: '20px 0 40px 0'}}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', margin: '15px 0'}}>
                    <p>{t('general.allReview')} ({productReviewSummary.all_count})</p>
                    {
                        _.size(review) > 0 &&
                        <Link underline='none' style={{ color: product?.display_code?.default ? theme.palette.primary.main : product?.display_code?.background_color }} to={`/review/${id}`} component={RouterLink}>
                            <Typography variant="body2" style={{fontSize:'0.75rem'}}>
                            {t('general.seeReview')}
                            </Typography>
                        </Link>
                    }
                </div>
                
                <ReviewsList reviews={review} />
            </div>
        );
    }

    // ---------- Variables ------------
    let discount = Math.round(((product?.retail_price - product?.sell_price) / product?.retail_price) * 100) || 0;

    return (
        <div>
            <Helmet>
                <title>{i18n.language === 'cn' ? product.title_cn : product.title_en}</title>
                <meta name="description" content={i18n.language === 'cn' ? product.title_cn : product.title_en} />
                <meta name="og:url" content={window.location.href} />
                <meta name="og:title" content={i18n.language === 'cn' ? product.title_cn : product.title_en} />
                <meta name="og:description" content={i18n.language === 'cn' ? product.title_cn : product.title_en} />
                {_.size(product.images_array) > 0 ?
                    <meta property="og:image" content={product.images_array[0]} />
                    : null}
            </Helmet>

            <TitleBar title={t('title.products')} backgroundColor="bg-theme" back displayCart currencyButton hexBkgColor={product?.display_code?.default ? theme.palette.primary.main : product?.display_code?.background_color} hexTextColor={product?.display_code?.default ? "#fff" : product?.display_code?.text_color} />{/* hexBkgColor="#222" hexTextColor="#fff" */}

            <div className='bg-theme p-b-150 header-radius' style={{ background: product?.display_code?.default ? theme.palette.primary.main : product?.display_code?.background_color }}></div>
            <div className='flex-c-m' style={{ width: '100%', maxWidth: '100%', padding: '0', marginTop: -120 }}>
                <style>
                    {
                        `
                        .image-gallery-right-nav{
                            right: 0px;
                        }
                        .image-gallery-left-nav {
                            left: 0px;
                        }
                        .image-gallery-bullets .image-gallery-bullet.active, .image-gallery-bullets .image-gallery-bullet:hover  {
                            background: ${product?.display_code?.default ? '#18267C' : product?.display_code?.background_color};
                        }
                        .image-gallery-bullets .image-gallery-bullet, .image-gallery-bullets .image-gallery-bullet:hover  {
                            border: 2px solid ${product?.display_code?.default ? '#18267C' : product?.display_code?.background_color};
                        }
                        .image-gallery-slides {
                            height: ${isFullscreen ? 500 : 300}px;
                        }
                        .image-gallery-content .image-gallery-slide .image-gallery-image {
                            width: ${isFullscreen ? '100%' : '250px'};
                            object-fit: ${isFullscreen ? 'contain' : 'cover'};
                        }
                        `
                    }
                </style>
                {
                    _.size(product?.images_array) > 0
                        ? <ImageGallery
                            ref={galleryRef}
                            items={generateImageSlide(product)}
                            autoPlay={false}
                            onErrorImageURL="/images/empty/no-image.png"
                            renderItem={(item) => {
                                return <WebpImg className={`image-gallery-image`} src={item.original} />
                            }}
                            renderThumbInner={(item) => {
                                return <WebpImg className={`image-gallery-image`} src={item.original} />
                            }}
                            showBullets={_.size(product?.images_array) > 1 ? true : false}
                            showFullscreenButton={false}
                            showThumbnails={false}
                            showPlayButton={false}
                            onClick={handleImageClick}
                        />
                        : <img src={'/images/empty/no-image.png'} className={classes.productImageDesign} alt='product' />
                }
            </div>

            <div className='p-lr-25'>
                <div className='flex-sb w-full' style={{alignItems:'flex-start'}}>
                    {
                        product?.sell_price !== product?.retail_price && discount > 0 &&
                        <div>
                            <style>
                                {
                                    `.ribbon-small::before {
                                        border-left: 20px solid #FFBF42;
                                        border-right: 20px solid #FFBF42;
                                    }`
                                }
                            </style>
                            <div className='ribbon-small' style={{ background: '#FFBF42' }}>
                                <Typography variant='body2' className='p-t-10 txt-center txt-upper' style={{ lineHeight: 1.2, fontSize: 12, color: '#f0f0f0' }}>
                                    <b>{discount}%<br/>{t('general.discount')}</b>
                                </Typography>
                            </div>
                        </div>
                    }

                    <div className={`w-full p-r-15 p-l-${product?.sell_price !== product?.retail_price && discount > 0 ? 15 : 0}`}>
                        <div className='w-full flex-sb-m p-b-8'>
                            <Typography variant='h5' className='cl-theme-dark' style={{ lineHeight: 1.2 }}><b>{i18n.language === 'cn' ? product?.title_cn : product?.title_en}</b></Typography>
                            <div className='flex-r-m'>
                                <IconButton aria-label="favorite" className={classes.iconButtonDesign} style={{ marginRight: 5 }} onClick={(accessToken && state.user_wishlist) ? dislikeProduct : likeProduct}>
                                    {
                                        state.user_wishlist
                                            ? <Favorite className='cl-theme fs-icon-small' style={{ fontSize: 18, color: product?.display_code?.default ? '#16114E' : product?.display_code?.background_color }} />
                                            : <FavoriteBorder className='cl-theme fs-icon-small' style={{ fontSize: 18, color: product?.display_code?.default ? '#16114E' : product?.display_code?.background_color }} />
                                    }
                                </IconButton>

                                {
                                    _.size(max_rank) > 0 &&
                                    <IconButton aria-label="favorite" className={classes.iconButtonDesign} onClick={() => { navigator.clipboard.writeText(shareLink); addAlert(t('profile.copySuccess'), "success"); }}>
                                        <ShareRounded className='cl-theme' style={{ fontSize: 18, color: product?.display_code?.default ? '#16114E' : product?.display_code?.background_color }} />
                                    </IconButton>
                                }
                            </div>
                        </div>
                        {/* <Typography variant='caption' className='cl-theme' >anti aging, anti oxidant</Typography> */}

                        <div className='p-t-5' style={{display: 'flex', flexWrap: 'wrap', gap: 6}}>
                            <div className={classes.featureDisplayDesign}>
                                <p className='cl-theme txt-center' style={{ color: product?.display_code?.default ? '#16114E' : product?.display_code?.background_color }}>{productReviewSummary?.total_sold} {t('item.sold')}</p>
                            </div>

                            <div className={classes.featureDisplayDesign}>
                                <p className='flex-m cl-theme txt-center' style={{ color: product?.display_code?.default ? '#16114E' : product?.display_code?.background_color }}>
                                    <FaStar className='fs-13' />&nbsp;{productReviewSummary?.average_rating}
                                </p>
                            </div>

                            <div className={classes.featureDisplayDesign}>
                                <p className='flex-m cl-theme txt-center' style={{ color: product?.display_code?.default ? '#16114E' : product?.display_code?.background_color }}>
                                    <RiHeartAddFill className='fs-13' />&nbsp;{_.map(category, (categoryItem) => (categoryItem?.[`name_${i18n.language}`])) }
                                </p>
                            </div>
                        </div>
                    </div>

                    
                </div>

                <div className='p-t-30'>
                    <Typography variant='body2' className='p-b-5 cl-label'><b>{t('item.cashbackRewards')}</b></Typography>

                    <Grid container>
                        <Grid item xs={3} className={classes.cashbackRewardsDesign} style={{ background: product?.display_code?.default ? '#18267C' : product?.display_code?.background_color }}>
                            <Typography variant='caption' style={{ color: product?.display_code?.default ? '#fff' : product?.display_code?.text_color , textAlign: 'center' }}>{product?.bv} BV</Typography>
                        </Grid>
                
                        {
                            _.map(product?.reward?.local?.wallet_amount, (point, idx) => {
                                const name = _.split(wallets?.[idx], '|');
                                return (
                                    <Grid item xs={3} key={idx} className={classes.cashbackRewardsDesign} style={{ background: product?.display_code?.default ? '#18267C' : product?.display_code?.background_color }}>
                                        <Typography variant='caption' className='txt-center' style={{ color: product?.display_code?.default ? '#fff' : product?.display_code?.text_color }}>
                                            {t('product.rewardDetail') + point + ' ' + (i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0])}
                                        </Typography>
                                    </Grid>
                                )
                            })
                        }
                        {/* <Grid item xs={3} className={classes.cashbackRewardsDesign} style={{ background: product?.display_code?.background_color }}>
                            <Typography variant='caption' style={{ color: '#F6F7F9' }}>{t('product.rewardDetail')} {product?.mp_reward?.amount} VP</Typography>
                        </Grid> */}
                    </Grid>
                </div>

                <div className='p-t-30'>
                    <Typography variant='body2' className='p-b-5 cl-label'><b>{t('product.productDescription')}</b></Typography>
                    <div 
                        className='cl-theme-dark lh-12 txt_truncate4'
                        dangerouslySetInnerHTML={{ 
                            __html: product?.[`descr_${i18n.language}`] 
                            ?.replace(/<p/g, '<p style="font-size: 12px"') 
                        }} 
                    />
                </div>

                {/* <div className='p-t-30'>
                    <Typography variant='body2' className='p-b-5 cl-label'><b>Ingredients (5)</b></Typography>

                    <Grid container spacing={1}>
                        {
                            _.map([0, 1, 2, 3, 4], (detail, idx) => (
                                <Grid key={idx} item xs={3}>
                                    <div className='flex-col-m'>
                                        <div className={classes.ingredientDisplayDesign}>
                                            <img src={'/images/products/mimax5/1b-apple-extract-polyphenols.png'} />
                                        </div>
                                        <Typography variant='body2' className='cl-theme-dark txt-center p-t-5' style={{ fontSize: 9, width: 70 }}>Apple Extract Polyphenols</Typography>
                                    </div>
                                </Grid>
                            ))
                        }
                    </Grid>
                </div> */}
            </div>

            <div className={`m-t-30 p-t-20 p-lr-25 ${classes.productDetailDesign}`}>
                <Tabs 
                    value={value} 
                    onChange={handleChange} 
                    variant="fullWidth" 
                    classes={{ indicator: product?.display_code?.default ? classes.indicatorDefaultDisplayDesign : classes.indicatorDisplayDesign }} 
                    style={product?.display_code?.default ? {} : { '--dynamic-color': "#16114E" }}
                    sx={{
                        '.MuiTab-root': {
                            minWidth: 100
                        }
                    }}
                >
                    <Tab 
                    label={t('product.details')} {...a11yProps(0)} 
                    classes={{ selected: product?.display_code?.default ? classes.selectedDefaultTabDesign : classes.selectedTabDesign }} 
                    style={product?.display_code?.default ? {minWidth: 100} : { '--dynamic-color': "#16114E" }}/>
                    <Tab 
                    label={t('product.delivery')} {...a11yProps(1)}
                     classes={{ selected: product?.display_code?.default ? classes.selectedDefaultTabDesign : classes.selectedTabDesign }} 
                     style={product?.display_code?.default ? {minWidth: 100} : { '--dynamic-color': "#16114E" }}/>
                    <Tab 
                    label={t('product.review')} {...a11yProps(2)} 
                    classes={{ selected: product?.display_code?.default ? classes.selectedDefaultTabDesign : classes.selectedTabDesign }} 
                    style={product?.display_code?.default ? {minWidth: 100} : { '--dynamic-color': "#16114E" }}/>
                </Tabs>

                <div>
                    <CustomTabPanel value={value} index={0}>
                        <div className='p-t-20 p-b-50 cl-theme-dark' dangerouslySetInnerHTML={{ __html: product?.[`descr_${i18n.language}`] ?.replace(/<p/g, '<p style="font-size: 12px"')  }} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <div className='p-t-20'>
                            <div>
                                {/* <p className='fs-14'><b>{t('item.shippingRegionRestriction')}</b></p> */}
                                <p className='fs-16 txt-upper cl-label'>
                                    {/* style={{ color: product?.display_code?.default ? theme.palette.primary.main : product?.display_code?.background_color }} */}
                                    <b>{t('item.standardDelivery')}</b>
                                </p>

                                <div>
                                    <ListItemText
                                        primary={t('item.prepareTime')}
                                        secondary={t('item.prepareTimeSub', { 'day': product?.prepare_time })}
                                        primaryTypographyProps={{
                                            variant: "subtitle1",
                                            style: { fontWeight: "bold", fontSize: 14 }
                                        }}
                                        secondaryTypographyProps={{
                                            style: {
                                                color: theme.palette.gray.main
                                            },
                                            variant: "caption"
                                        }}
                                    />
                                    <ListItemText
                                        primary={t('item.deliveryTime')}
                                        secondary={t('item.deliveryTimeSub')}
                                        primaryTypographyProps={{
                                            variant: "subtitle1",
                                            style: { fontWeight: "bold", fontSize: 14 }
                                        }}
                                        secondaryTypographyProps={{
                                            style: {
                                                color: theme.palette.gray.main
                                            },
                                            variant: "caption"
                                        }}
                                    />
                                </div>
                            </div>

                            <div className='p-t-20'>
                                <p className='fs-16 txt-upper cl-label'><b>{t('product.specsPackaging')}</b></p>
                                
                                <div className='p-t-10'>
                                    <ListItemText
                                        style={{ marginTop: 0 }}
                                        primary={t('item.category')}
                                        secondary={
                                            <div style={{display:'flex', alignItems:'center', flexWrap:'wrap', gap:5}}>
                                                {
                                                    _.map(category, (categoryItem, categoryIndex) => {
                                                        return (
                                                            <Box key={categoryItem?.id} display="flex" flexDirection="row">
                                                                <Link underline='none' href={`/category/${categoryItem?.id}`} style={{ color: product?.display_code?.default ? theme.palette.primary.main : product?.display_code?.background_color }} >
                                                                    <Typography variant="body2" style={{fontSize:'0.75rem'}}>
                                                                        {categoryItem?.[`name_${i18n.language}`]}
                                                                    </Typography>
                                                                </Link>
                                                                {
                                                                    categoryIndex === lastIndex
                                                                        ? null
                                                                        : <Typography variant="body2" style={{ paddingLeft: 5, paddingRight: 5, fontSize:'0.75rem' }}>{' > '}</Typography>
                                                                }
                                                            </Box>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        primaryTypographyProps={{
                                            variant: "subtitle1",
                                            style: { fontWeight: "bold", fontSize: 14, marginTop: 0 }
                                        }}
                                        secondaryTypographyProps={{
                                            style: {
                                                color: theme.palette.gray.main
                                            },
                                            variant: "caption"
                                        }}
                                    />
                                    <ListItemText
                                        primary={t('item.brand')}
                                        secondary={product?.goods_brand ?  stringToLang(product?.goods_brand) : t('item.noBrand')}
                                        primaryTypographyProps={{
                                            variant: "subtitle1",
                                            style: { fontWeight: "bold", fontSize: 14 }
                                        }}
                                        secondaryTypographyProps={{
                                            style: {
                                                color: theme.palette.gray.main
                                            },
                                            variant: "caption"
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        { reviewDisplay() }
                    </CustomTabPanel>
                </div>

               

            </div>

            <div className={classes.stickyFooter}>
                <Grid container className='p-tb-10 p-lr-20' spacing={1} alignItems='center' justify='space-between'>
                    <Grid item xs={6}>
                        <div className='flex-m'>
                            <Typography variant='body1' style={{ color: '#4E4E4E', paddingRight: 10, lineHeight: 1.2 }}>
                                <b>{currency}{currencyFormat(product?.sell_price * currencyRate[`${currency}`] || 0)}</b>
                            </Typography>
                            {
                                product?.sell_price !== product?.retail_price &&
                                <Typography variant='caption' style={{ textDecoration: 'line-through', lineHeight: 1.2, color: '#ADADAD' }}>{currency}{currencyFormat(product?.retail_price * currencyRate[`${currency}`] || 0)}</Typography>
                            }
                        </div>
                        {/* <Typography variant='caption' style={{ color: '#ADADAD', lineHeight: 1.1 }}>≈ AP 157.00</Typography> */}
                    </Grid>

                    <Grid item xs={6} className='flex-r'>
                        {/* <Button variant='outlined' onClick={() => toggleDrawer(true)} className={classes.buttonOutlinedDisplayDesign} style={{ border: `2px solid ${!product?.display_code?.default ? product?.display_code?.background_color : '#18267C'}` }}>
                            <Typography variant='caption' className='txt-title' style={{ fontWeight: 'bold', fontSize: mobileView ? 10 : 12, color: !product?.display_code?.default ? product?.display_code?.background_color : '#18267C' }}>{t('item.buyNow')}</Typography>
                        </Button> */}

                        <Button variant='contained' onClick={() => toggleDrawer(true)} className={classes.buttonContainedDisplayDesign} style={{ background: !product?.display_code?.default ? product?.display_code?.background_color : '#18267C' }}>
                            <Typography variant='caption' className='txt-title' style={{ fontWeight: 'bold', fontSize: mobileView ? 10 : 12, color: !product?.display_code?.default ? product?.display_code?.text_color : '#f0f0f0' }}>{t('product.select')}</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </div>

            <ProductOption 
                id={id} 
                toggleDrawer={toggleDrawer} 
                open={open}
                product={product} 
                state={state} 
                setState={setState}
                warehouse={warehouse}
                setWarehouse={setWarehouse}
                page={page} 
                setPage={setPage} 
            />
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    productImageDesign: {
        // position: 'absolute',
        // width:230,
        height:230, 
        objectFit:'cover',
        // padding: '0px 30px',
    },
    ingredientDisplayDesign: {
        width: 75,
        height: 75,
        aspectRatio: 1 / 1,
        padding: 10,
        borderRadius: 10,
        boxShadow: '3px 3px 4px 0px #FFFFFF inset, 3px 3px 4px 0px #00000008',
    },
    productDetailDesign: {
        boxShadow: '0px 0px 0px 0px #FFFFFF, 0px 3px 4px 0px #0000001A inset',
    },
    cashbackRewardsDesign: {
        display: 'flex',
        justifyContent: 'center',
        padding: '5px',
        marginTop: 5,
        marginRight: 5,
        borderRadius: '10px 0px 10px 0px',
        boxShadow: '3px 3px 4px 0px #FFFFFF57 inset, 3px 3px 4px 0px #00000008',
    },
    iconButtonDesign: {
        padding: 5,
        minWidth: 34,
        aspectRatio: 1 / 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 49,
        background: '#F6F7F9',
        boxShadow: '2px 2px 4px 0 #0002, inset 2px 2px 3px 0 #fff',
    },
    featureDisplayDesign: {
        borderRadius: 48,
        background: '#E9E9E9',
        padding: '2px 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform:'uppercase',
        fontSize:11,
        whiteSpace:'nowrap',
    },
    indicatorDefaultDisplayDesign: {
        backgroundColor: '#16114E',
    },
    selectedDefaultTabDesign: {
        color: '#16114E',
    },
    indicatorDisplayDesign: {
        backgroundColor: 'var(--dynamic-color)',
        fontWeight:'bold'
    },
    selectedTabDesign: {
        color: 'var(--dynamic-color)',
    },
    buttonOutlinedDisplayDesign: {
        borderRadius: 49,
        padding: '6px 12px',
    },
    buttonContainedDisplayDesign: {
        boxShadow: '3px 3px 8px 0px #FFFFFF40 inset, -3px -3px 8px 0px #0000002E inset',
        borderRadius: 49,
    },
    stickyFooter: {
        width: '100%',
        maxWidth: 420,
        position: 'fixed',
        bottom: 0,
        backgroundColor:'#f2f2f2',
        zIndex: 10,
        minHeight: 70,
    },
}));