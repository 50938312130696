import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { SELLER_URL } from '../../configs/Config';

import { getUrl, logoutUser } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';

// MUI
import { Container, Link } from '@material-ui/core';

// ICON
import { FiChevronRight, FiHeadphones, FiSettings, FiShoppingBag, FiUser } from 'react-icons/fi';
import { TbBinaryTree } from "react-icons/tb";
import { BsShop } from "react-icons/bs";

// COMPONENTS or LAYOUT
import TitleBar from '@layouts/TitleBar';

export default function Settings() {
    const dispatch = useDispatch();
    const history = useHistory();

    const { t, i18n } = useTranslation();
    const { accessToken , role } = useSelector(state => state.general);
    const { username, email, mobile, max_rank: rank, max_mrank, mobile_country_code } = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();

    const [sales, setSales] = useState({
        personal: 0,
        sponsorGroup: 0,
    });
    const [type, setType] = useState('/images/rank_logo/rank-0s.png');
    const [mrankColor, setMrankColor] = useState('bronze');

    // ------------ APIs --------------
    useEffect(() => {
        if(accessToken){
            getUrl(`/user/total-sales`).then(response => {
                if (response.status) {
                    setSales({
                        personal: response.data?.personal_sales ? response.data?.personal_sales : '0.00',
                        sponsorGroup: response.data?.sponsor_group_sales ? response.data?.sponsor_group_sales : '0.00',
                    });
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }, []);

    useEffect(() => {
        if (max_mrank) {
            switch (max_mrank.name_display) {
                case '1 Star Manager':
                    setMrankColor('bronze')
                    setType('/images/rank_logo/rank-1s.png')
                    break;
                case '2 Star Manager':
                    setMrankColor('bronze')
                    setType('/images/rank_logo/rank-2s.png')
                    break;
                case '3 Star Manager':
                    setMrankColor('bronze')
                    setType('/images/rank_logo/rank-3s.png')
                    break;
                case '4 Star Manager':
                    setMrankColor('silver')
                    setType('/images/rank_logo/rank-4s.png')
                    break;
                case '5 Star Manager':
                    setMrankColor('silver')
                    setType('/images/rank_logo/rank-5s.png')
                    break;
                case '6 Star Manager':
                    setMrankColor('silver')
                    setType('/images/rank_logo/rank-6s.png')
                    break;
                case '7 Star Manager':
                    setMrankColor('gold')
                    setType('/images/rank_logo/rank-7s.png')
                    break;
                case '8 Star Manager':
                    setMrankColor('gold')
                    setType('/images/rank_logo/rank-8s.png')
                    break;
                case '9 Star Manager':
                    setMrankColor('gold')
                    setType('/images/rank_logo/rank-9s.png')
                    break;
                case '10 Star Manager':
                    setMrankColor('platinum')
                    setType('/images/rank_logo/rank-10s.png')
                    break;
                default:
                    setMrankColor('bronze')
                    setType('/images/rank_logo/rank-0s.png')
                    break;
            }
        }
    }, []);

    return (
        <div>
            {accessToken ?
                <TitleBar backgroundColor="bg-theme"  btnRegister displayCart languageButton={false} />
                :
                <TitleBar  btnRegister languageButton={false} />
            }
            {accessToken ?
                <>
                    <div className='bg-theme p-b-200 header-radius'></div>
                    <div style={{ marginTop: -180 }}>
                        <div className='glass3 p-t-20 p-lr-20 p-b-80 flex-sb-m bor20' style={{width: 350, maxWidth: '85%', margin: '0 auto' }}>
                            <div className='clwhite fs-content'  style={{width: 'calc(100% - 140px)'}}>
                                <div>
                                    <p className='fs-title w-full txt_truncate'>{username}</p>
                                    <p className='fs-content w-full txt_truncate'>{(rank && _.size(rank) > 0) ? rank.name_display : t('general.guest')}</p>
                                </div>
                                <div className='p-t-20'>
                                    <p className='fs-content w-full txt_truncate'>{`${mobile_country_code !== null ? `${mobile_country_code} - `:''} ${mobile}`}</p>
                                    <p className='fs-content w-full txt_truncate'>{email}</p>
                                </div>
                            </div>
                            {/* <div>
                                <img src={type} alt="card" style={{ width: 70, borderRadius: 15 }} />
                            </div> */}
                            <div className='pos-relative' style={{width:120, maxWidth:'50%'}}>
                                <img src={type} alt="mrank" className='w-full' />

                                {
                                    (rank && _.size(rank) > 0) && <>
                                        {
                                            rank?.id < 3
                                                ? <>
                                                    {/* // FOR M1 & M2 Design */}
                                                    <div className='pos-absolute flex-m flex-col' style={{ right: 20, bottom: 39, filter: 'drop-shadow(-1px 1px 1px #0002)' }}>
                                                        <div className={`rank-${mrankColor}-base`} style={{ width: 4, height: 20, boxShadow: 'inset -1px 0 1px 0 #0002' }}></div>
                                                        <div className={`bor50 flex-c-m rank-${mrankColor}`} style={{ width: 25, height: 25, marginTop: -41 }}>
                                                            <div className={`bor50 p-all-2 rank-${mrankColor}-base flex-c-m`} style={{ width: 21, height: 21, boxShadow: 'inset 1px 1px 1px 0 #2223' }}>
                                                                <p className={`fs-10 rank-${mrankColor}-text`}><b>{rank.name_display}</b></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : <>
                                                    {/* // FOR M3 & M4 Design */}
                                                    <div className='pos-absolute flex-m flex-col' style={{ right: 20, bottom: 39, filter: 'drop-shadow(-1px 1px 1px #0002)' }}>
                                                        <div className={`rank-${mrankColor}-base`} style={{ width: 4, height: 20, boxShadow: 'inset -1px 0 1px 0 #0002' }}></div>
                                                        <div className={`bor50 flex-c-m rank-${mrankColor}`} style={{ width: 25, height: 25, marginTop: -41 }}>
                                                            <div className={`bor50 rank-${mrankColor}-base flex-c-m`} style={{ width: 22, height: 22, boxShadow: 'inset 1px 1px 1px 0 #2223' }}>
                                                                <div className={`bor50 p-all-2 flex-c-m rank-${mrankColor}`} style={{ width: 19, height: 19 }}>
                                                                    <p className={`fs-10 rank-${mrankColor}-text-vip`}><b>{rank.name_display}</b></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                        <div className='gradient-blue bor15 flex-sb-m w-full p-tb-15' style={{ width: '90%', margin: '-65px auto 0', boxShadow: '0px 8px 10px 0px rgba(124, 151, 255, 0.30), 0px -4px 7px 0px rgba(27, 143, 150, 0.15) inset, 0px 2px 4px 0px rgba(255, 255, 255, 0.45) inset' }}>
                            <div className='txt-center clwhite' style={{ width: 'calc(50% - 2px)' }}>
                                <p className='fs-menu-digit'><NumberFormat value={sales.personal} decimalScale={2} displayType={'text'} thousandSeparator={true} /></p>
                                <p className='txt-upper fs-remark'>{t('settings.personalSales')}</p>
                            </div>
                            <div style={{ width: 4, height: 33, background: 'rgba(255, 255, 255, 0.32)', boxShadow: '#307BEF 2px 0px 2px 0px inset' }}></div>
                            <div className='txt-center clwhite' style={{ width: 'calc(50% - 2px)' }}>
                                <p className='fs-menu-digit'><NumberFormat value={sales.sponsorGroup} decimalScale={2} displayType={'text'} thousandSeparator={true} /></p>
                                <p className='txt-upper fs-remark'>{t('settings.groupSales')}</p>
                            </div>
                        </div>
                    </div>
                </>
                :
                null
            }
            <Container className='p-t-10'>
                {accessToken &&
                    <>
                        <Link underline='none' component={RouterLink} to="/my-profile" className='pointer'>
                            <div className='flex-sb-m w-full translateX'>
                                <div className='flex-m p-tb-15'>
                                    <FiUser className='fs-icon cl-theme' />
                                    <div className='p-l-15'>
                                        <p className='fs-title cl-theme'><b>{t('title.account')}</b></p>
                                        <p className='fs-subtitle cl-label'>{t('settings.subTitle.account')}</p>
                                    </div>
                                </div>
                                <FiChevronRight className='fs-icon cl-theme' />
                            </div>
                        </Link>

                        <Link underline='none' component={RouterLink} to="/my-order" className='pointer'>
                            <div className='flex-sb-m w-full translateX'>
                                <div className='flex-m p-tb-15'>
                                    <FiShoppingBag className='fs-icon cl-theme' />
                                    <div className='p-l-15'>
                                        <p className='fs-title cl-theme'><b>{t('title.order')}</b></p>
                                        <p className='fs-subtitle cl-label'>{t('settings.subTitle.order')}</p>
                                    </div>
                                </div>
                                <FiChevronRight className='fs-icon cl-theme' />
                            </div>
                        </Link>
                        {
                            _.size(_.intersection(role, ['merchant', 'super-merchant'])) == 0 &&
                                <Link underline='none' onClick={() => window.open(`${SELLER_URL}seller/register?username=${username}`)}  className='pointer'>
                                    <div className='flex-sb-m w-full pointer translateX'>
                                        <div className='flex-m p-tb-15'>
                                            <BsShop className='fs-25 cl-theme' />
                                            <div className='p-l-15'>
                                                <p className='fs-16 cl-theme'><b>{t('profile.becomeSeller')}</b></p>
                                                <p className='cl-label'>{t('settings.subTitle.seller')}</p>
                                            </div>
                                        </div>
                                        <FiChevronRight className='fs-25 cl-theme' />
                                    </div>
                                </Link>
                        }
                        {
                            _.size(_.intersection(role, ['merchant', 'super-merchant'])) > 0 &&
                                <Link underline='none' onClick={() => window.open(`${SELLER_URL}login`)} >
                                    <div className='flex-sb-m w-full pointer translateX'>
                                        <div className='flex-m p-tb-15'>
                                            <BsShop className='fs-25 cl-theme' />
                                            <div className='p-l-15'>
                                                <p className='fs-16 cl-theme'><b>{t('profile.sellerSite')}</b></p>
                                                <p className='cl-label'>{t('profile.sellerSite')}</p>
                                            </div>
                                        </div>
                                        <FiChevronRight className='fs-25 cl-theme' />
                                    </div>
                                </Link>
                        }
                        <Link underline='none' component={RouterLink} to="/community-setting">
                            <div className='flex-sb-m w-full pointer translateX'>
                                <div className='flex-m p-tb-15'>
                                    <TbBinaryTree className='fs-icon cl-theme' />
                                    <div className='p-l-15'>
                                        <p className='fs-title cl-theme'><b>{t('profile.genealogy')}</b></p>
                                        <p className='fs-subtitle cl-label'>{t('settings.subTitle.genealogy')}</p>
                                    </div>
                                </div>
                                <FiChevronRight className='fs-icon cl-theme' />
                            </div>
                        </Link>
                    </>
                }
                <Link underline='none' component={RouterLink} to="/help-center">
                    <div className='flex-sb-m w-full pointer translateX'>
                        <div className='flex-m p-tb-15'>
                            <FiHeadphones className='fs-icon cl-theme' />
                            <div className='p-l-15'>
                                <p className='fs-title cl-theme'><b>{t('support.support')}</b></p>
                                <p className='fs-subtitle cl-label'>{t('settings.subTitle.support')}</p>
                            </div>
                        </div>
                        <FiChevronRight className='fs-icon cl-theme' />
                    </div>
                </Link>

                <Link underline='none' component={RouterLink} to="/setting">
                    <div className='flex-sb-m w-full translateX'>
                        <div className='flex-m p-tb-15'>
                            <FiSettings className='fs-icon cl-theme' />
                            <div className='p-l-15'>
                                <p className='fs-title cl-theme'><b>{t('profile.settings')}</b></p>
                                <p className='fs-subtitle cl-label'>{t('settings.subTitle.settings')}</p>
                            </div>
                        </div>
                        <FiChevronRight className='fs-icon cl-theme' />
                    </div>
                </Link>


                <div className='p-t-30'>
                    {
                        accessToken ?
                            <Link underline='none' className='w-full' component="button" onClick={() => {
                                logoutUser(dispatch);
                                history.replace('/login');
                            }}>
                                <div className='btn-outlined-theme w-full translateY'>
                                    <p className='fs-button'>{t('button.logout')}</p>
                                </div>
                            </Link>
                            :
                            <Link underline='none' component={RouterLink} to="/login">
                                <div className='btn-outlined-theme w-full translateY'>
                                    <p className='fs-button'>{t('button.login')}</p>
                                </div>
                            </Link>
                    }
                </div>
            </Container>
        </div>
    );
}