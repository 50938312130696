import React, { useState, useEffect, useRef } from 'react';
// import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
// import { useQuery } from '@helper/Tools';

import _ from 'lodash';

import Order from './Order';
import OrderDetail from './OrderDetail';

//MUI
import { makeStyles, useTheme } from '@material-ui/core/styles';
// import {  Container, Grid, Box, Typography } from '@material-ui/core';

//COMPONENTS & LAYOUTS
import TitleBar from '@layouts/TitleBar';

export default function OrderHistory() {

    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    let { oid } = useParams();
    let history = useHistory();
    const isMountedRef = useRef(null);
    // const query = useQuery();

    const displaySubPage = () => {
        if(oid) {
            return <OrderDetail t={t} i18n={i18n} oid={oid} language={i18n.language} />
        } else {
            return <Order t={t} i18n={i18n} language={i18n.language} />
        }
    }

    useEffect(() => {
        isMountedRef.current = true;
        return () => { isMountedRef.current = false };
    }, []);

    return (
        <div>
            <TitleBar backgroundColor="bg-theme" back btnRegister />
            <div>
                {displaySubPage()}
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0 10px 0',
        // backgroundColor: 'white',
    },
    root2: {
        // padding: '10px 0px 30px 0px',
        background: theme.palette.white.mobileBkg,
        // padding:'0 0 80px',
        minHeight: '100%'
    }
}));