import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box } from '@material-ui/core';
import TitleBar from '@layouts/TitleBar';


export default function TermOfUse() {
    const { t } = useTranslation();

    return (
        <div>
           <TitleBar backgroundColor="bg-theme" back displayUsername />
            <div className='bg-theme p-b-30 header-radius'>
                <p className='txt-center fs-header clwhite p-t-30'>{t('support.refundAgreement')}</p>
            </div>
     
            <div className='p-t-30'>
                <div className='lh-17'>
                    <Container>
                        <p style={{ marginTop: '30px' }}><b style={{ color: '#333' }}>Application for Refund and/or Return of an Item</b><br />Buyer may only apply for refund by and/or return of an item ("Item") to the Seller in the following circumstances:</p>
                        <ul style={{ listStyle: 'none' }}>
                            <li>•	The Item has not been received by Buyer;</li>
                            <li>•	The Item received is incomplete (missing quantity or accessories);</li>
                            <li>•	Seller has delivered an Item that does not match the agreed specification (e.g. wrong size, colour, etc.) to Buyer;</li>
                            <li>•	The Item delivered to Buyer is materially different from the description provided by Seller in the listing of the Item;</li>
                            <li>•	The Item received has physical damage (e.g. dented, scratched, shattered);</li>
                            <li>•	The Item received is faulty (e.g. malfunction, does not work as intended);</li>
                            <li>•	By way of private agreement with Seller and Seller must send his/her confirmation to MDS confirming such agreement;</li>
                        </ul>

                        <p>The Buyer's application must be submitted via the MDS Website/Apps.</p>

                        <p>MDS will review each Buyer's application on a case-by-case basis and, if MDS deems necessary, MDS may consult the Seller in the process of reviewing the application. MDS shall in its sole discretion, determine whether the Buyer's application shall be successful. The Seller shall be fully responsible for all direct and/or indirect losses, claims, damages, costs and expenses successfully claimed by the Buyer arising from such application for refund and/or return of any Item.</p>

                        <p>In addition thereto, the Seller shall compensate, defend, indemnify and hold MDS harmless from and against any losses which MDS may suffer or incur, directly or indirectly arising out of or resulting from or in connection with such application for refund and/or return of an Item. In this regard, MDS reserves all rights and entitlement to deduct and set-off the requisite sum of monies retained in the Seller's accounts maintained with MDS.</p>

                        <p>Both the Buyer and the Seller accept and agree that MDS is merely a platform through which the Buyer and Seller undertake commercial transactions between the Buyer and the Seller. MDS is not and shall not be responsible for any products sold or purchased between the Buyer and the Seller. MDS therefore shall not under any circumstances be responsible in any manner whatsoever for any losses, claims, damages, costs and expenses incurred or to be incurred by the Seller or the Buyer in connection with any application for refund and/or return of the Item.</p>

                        <p style={{ marginTop: '30px' }}><b style={{ color: '#333' }}>Seller's Obligations</b><br />
                            When MDS receives an application from Buyer for the refund by and/or return of the Item to the Seller, MDS will notify Seller in writing. Seller may respond to Buyer's application according to the steps provided by MDS in the written notification. Seller must respond with proposed measures and steps to deal with such application within 5 days from the date of the written notification from MDS. Should MDS not hear from Seller within the stipulated period, MDS shall be entitled to proceed to assess, process, reject and/or approve Buyer's application without further notice to Seller.</p>
                        <p>MDS will review each Seller's response on a case-by-case basis and, in its sole discretion, determine whether Buyer's application may be successful against the circumstances stated by Seller. In the event that MDS approves the Buyer's application, MDS shall with no further notification to the Seller, proceed to process the cash refund to and return of the Item by the Buyer at the Seller's costs and expenses.</p>

                        <p style={{ marginTop: '30px' }}><b style={{ color: '#333' }}>Condition of Returning Item</b><br />
                            To enjoy a hassle-free experience when returning the Item, Buyer should ensure that the Item, including any complimentary items such as accessories that come with the Item, must be returned to Seller in the condition received by Buyer on delivery. We recommend that the Buyer takes a photo of the Item upon receipt.</p>

                        <p style={{ marginTop: '30px' }}><b style={{ color: '#333' }}>Liability of Return Shipping Fee</b><br />
                            In the scenario of an unforeseen error from the Seller's end (i.e - damaged, faulty or wrong Item delivered to the Buyer), the Seller will bear the Buyer's return shipping fee.</p>
                        <p>In the scenario of the Buyer's change of mind, Buyer shall get Seller's consent prior to the return request and Buyer will bear the return shipping fee.</p>
                        <p>In the scenario where both Seller-Buyer disputing the party liable for the return shipping fee, MDS at its sole discretion will determine whether the Buyer or the Seller shall be liable for the return shipping fee.</p>

                        <p style={{ marginTop: '30px' }}><b style={{ color: '#333' }}>Refunds</b><br />
                            Buyer will only be refunded after MDS has received the confirmation from Seller that Seller has received the returned Item in condition acceptable to MDS. In the event where MDS does not hear from Seller within 7 days from the date of MDS's request for such confirmation, MDS will be at liberty to refund the applicable sum to Buyer without further notice to Seller. The refund will be made to Buyer's credit/debit card or designated bank account, whichever is applicable.</p>
                        {/* <p>Seller's subscription fee is non-refundable.</p> */}

                        <p style={{ marginTop: '30px' }}><b style={{ color: '#333' }}>Communication Between Buyer and Seller</b><br />
                            MDS encourages both Buyer and Seller to communicate with each other in the event where a problem arises in a transaction. Since MDS is a platform for users to conduct trading, Buyer should contact Seller directly for any issue relating to the Item purchased.</p>


                    </Container>
                </div>
            </div>
        </div>
    );
}
