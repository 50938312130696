import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { getUrl ,putUrl} from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';

//MUI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Container, Link } from '@material-ui/core';

//ICON
import { FiChevronRight } from "react-icons/fi";

//COMPONENTS & LAYOUTS
import TitleBar from '@layouts/TitleBar';
import Slider from "react-slick";
import PromotionsCard from '@components/PromotionsCard';

export default function NotificationDetail() {

    // const [page, setPage] = useState(1);∫

    const { t, i18n } = useTranslation();
    const styles = useStyles();
    
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const theme = useTheme();
    const {id} = useParams();
    const [notification, setNotifications]= useState([]);
    const [promotions, setPromotions] = useState([]);

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;

        getUrl(`notifications`).then(response => {
            if (response.status) {
                if (isMountedRef.current) {
                    const promotions = response.data.data.filter(notification => notification.type === 'promotions');

                    setPromotions(promotions);
                    setLoading(false);
                }
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [ addAlert]);

    useEffect(() => {
        const fetchNotificationDetails = async () => {
            setLoading(true);
            isMountedRef.current = true;

            try {
                const response = await getUrl(`notifications/${id}`);
                if (response.status && isMountedRef.current) {
                    setNotifications(response.data);
                    setLoading(false);
                }
            } catch (error) {
                addAlert(JSON.stringify(error.message));
            }
        };

        fetchNotificationDetails();

        return () => { isMountedRef.current = false };
    }, [id, addAlert, setLoading]);

    useEffect(() => {
        setLoading(true);
        putUrl(`notifications/${id}`).then(response => {
            if (response.status) {
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        // eslint-disable-next-line
    }, []);

    const getImage = () => {
        const lang = i18n.language;
        return lang === 'cn' ? '/images/banner/promo-cn.png' : '/images/banner/promo-en.png';
    };

    const settingsPromotion = {
        infinite: false,
        speed: 500,
        slidesToShow: 1.25,
        slidesToScroll: 1,
        arrows: false,
    }

    const filteredPromotions = promotions.filter(promotion => promotion.id !== parseInt(id));

    return (
        <div>
            <TitleBar back btnRegister backgroundColor="bg-theme" />
            <div className='bg-theme p-b-180 header-radius txt-center'>
                <div className='subtitle-box'>
                <p className='fs-header p-t-30 clwhite'><b>{t('title.promotions')}</b></p>
                <p className='fs-subheader cl-label lh-11' style={{ wordBreak: 'break-word'}}>
                {notification[`subject_${i18n.language}`] ? notification[`subject_${i18n.language}`] : notification.subject}</p>
                </div>
            </div>
            <Container>
                <div className='p-all-10 bor25 bgwhite' style={{ marginTop: -150, boxShadow: '0 4px 4px 0 #0002' }}>
                    <div>
                        <img src={getImage()} alt='promotion' className='w-full bor15' style={{ objectFit: 'cover' }} />
                    </div>
                </div>
                <div className='p-t-20 p-b-10 p-lr-15 fs-content cl-text-dark' style={{  marginBottom: "15%", wordWrap: 'break-word' }}>
                    {
                        notification.message &&
                        <div dangerouslySetInnerHTML={{ __html: notification[`message_${i18n.language}`] ? notification[`message_${i18n.language}`] : notification.message }} />
                    }
                </div>
                {filteredPromotions.length > 0 && (
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} p={2}>
                            <p className='fs-title cl-theme'>{t('title.promotions')}</p>
                            <Link component={RouterLink} to="/notification?mt=promotions" underline="none" className='flex-m translateX'>
                                <p className='cl-theme fs-content cl-theme p-r-5'>{t('home.more')}</p>
                                <FiChevronRight/>
                            </Link>
                            
                        </div>
                    </div>
                )}
            </Container>
            <Slider {...settingsPromotion} style={{ marginLeft: '20px'}}>
                {filteredPromotions.slice(0, 3).map(promotion => (
                    <PromotionsCard 
                        key={promotion.id} 
                        promotion={promotion}
                    />
                ))}
            </Slider>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
      },
}));