import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { getUrl ,putUrl} from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';

//MUI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Container, Link } from '@material-ui/core';

//ICON
import { FiChevronRight } from "react-icons/fi";

//COMPONENTS & LAYOUTS
import TitleBar from '@layouts/TitleBar';
import Slider from "react-slick";
import AnnouncementCard from '@components/AnnouncementCard';


export default function AnnouncementDetails() {
    
    const [page, setPage] = useState(1);

    const { t, i18n } = useTranslation();
    const styles = useStyles();
    
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const theme = useTheme();
    const {id} = useParams();
    const [notification, setNotifications]= useState([]);
    const [announcements, setAnnouncements] = useState([]);

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;

        getUrl('member_announcements').then(res => {
            if (isMountedRef.current) {
                setAnnouncements(res.data);
                setLoading(false);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
            setLoading(false);
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [ addAlert]);

    useEffect(() => {
        const fetchNotificationDetails = async () => {
            setLoading(true);
            isMountedRef.current = true;

            try {
                const response = await getUrl(`member_announcements/${id}`);
                if (response.status && isMountedRef.current) {
                    setNotifications(response.data);
                    setLoading(false);
                }
            } catch (error) {
                addAlert(JSON.stringify(error.message));
            }
        };

        fetchNotificationDetails();

        return () => { isMountedRef.current = false };
    }, [id, addAlert, setLoading]);


    const settingsAnnouncement = {
        infinite: false,
        speed: 500,
        slidesToShow: 2.25,
        slidesToScroll: 1,
        arrows: false,
    }

    const filteredAnnouncements = announcements.filter(announcement => announcement.id !== parseInt(id));

    return (
        <div>
            <TitleBar back btnRegister backgroundColor="bg-theme" />
            <div className='bg-theme p-b-180 header-radius txt-center'>
                <div className='subtitle-box'>
                <p className='fs-header p-t-30 clwhite'><b>{t('title.announcements')}</b></p>
                <p className='fs-subheader lh-11 cl-label' style={{ wordBreak: 'break-word'}}>
                {notification[`title_${i18n.language}`] ? notification[`title_${i18n.language}`] : notification.title}</p>
                </div>
            </div>
            <Container>
                <div className='p-all-10 bor25 bgwhite' style={{ margin: "-150px auto 0", boxShadow: '0 4px 4px 0 #0002', width: "80%" }}>
                    {notification.image && (
                        <img src={notification.image.file_name} alt='announcement' className='w-full bor15' style={{ objectFit: 'cover', aspectRatio: "1 / 1" }}/>
                    )}
                </div>
                <div className='p-t-20 p-b-10 p-lr-15 fs-14 cl-text-dark' style={{ marginBottom: "15%", wordWrap: 'break-word' }}>
                    {
                        notification.content &&
                        <div dangerouslySetInnerHTML={{ __html: notification.content[i18n.language] ? notification.content[i18n.language] : notification.content.en }} />
                    }
                </div>
                {filteredAnnouncements.length > 0 && (
                    <div style={{ marginBottom: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} p={2}>
                            <p className='fs-title cl-theme'>{t('title.announcements')}</p>
                            <Link underline="none" component={RouterLink} to="/announcement" className='flex-m translateX'>
                                <p className='cl-theme fs-content'>{t('home.more')}</p>
                                <FiChevronRight className='fs-icon-small'/>
                            </Link>
                        </div>
                    </div>
                )}
            </Container>
            <Slider {...settingsAnnouncement} style={{ marginLeft: '20px'}}>
                {filteredAnnouncements.slice(0, 6).map((announcement) => (
                    <AnnouncementCard 
                        key={announcement.id} 
                        announcement={announcement}
                    />
                ))}
            </Slider>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
      },
}));